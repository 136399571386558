<template>
  <NuxtLink :to="getSummonerPageLink(summoner)" class="summoner-search__result">
    <div class="summoner-search__result-info">
      <SummonerProfileIcon :summoner="summoner" size="4rem"/>

      <span class="summoner-search__result-name">
        {{ summoner.gameName }}<span class="summoner-search__result-tag"> #{{ summoner.tagLine }}</span>
      </span>

      <div class="summoner-search__result-region">
        {{ summoner.region }}
      </div>
    </div>

    <div v-if="rankedEntry && size === 'full'" class="summoner-search__result-rank">
      {{ capitalizeString(rankedEntry.tier) }}
      {{ isHighElo(rankedEntry.tier) ? '' : rankedEntry.division }} -
      {{ rankedEntry.leaguePoints }}LP

      <RankedTierRegaliaIcon :tier="rankedEntry?.tier"/>
    </div>
  </NuxtLink>
</template>

<script lang="ts" setup>
import { capitalizeString } from "~/helpers/capitalizeString";
import { isHighElo } from "~/helpers/isHighElo";
import { getPatch } from "~/utils/patch";
import { RankedQueue } from "~/types/riot";
import { SummonerSearchResultType } from "~/graphql/query/findSummoners/findSummoners";

interface Props {
  summoner: SummonerSearchResultType;
  size?: "full" | "minimal";
}

const props = defineProps<Props>();

const rankedEntry = computed(() => props.summoner.rankedEntries.find(e => e.set === getSetFromPatch(getPatch(props.summoner.region).current.patchId).number && e.queueType === RankedQueue.RANKED_TFT));
</script>

<style lang="scss" scoped>
.summoner-search__result {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  padding: 0.4rem;
  transition: all 0.25s ease;
  border-radius: 0.3rem;

  &:hover {
    background-color: var(--UI-Accent-60);
  }

  .summoner-search__result-info {
    display: flex;
    align-items: center;
    gap: 1rem;

    .summoner-search__result-name {
      gap: 0.4rem;

      .summoner-search__result-tag {
        font-size: 1.2rem;
        opacity: 0.6;
        white-space: nowrap;
      }
    }

    .summoner-search__result-region {
      font-size: 1.2rem;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0.2rem 0.6rem;
      background-color: var(--UI-Secondary);
      border-radius: 0.3rem;
      white-space: nowrap;
    }
  }

  .summoner-search__result-rank {
    font-size: 1.2rem;
    margin-left: auto;
    display: flex;
    align-items: center;
    gap: 1rem;
    margin-right: 1rem;
  }
}
</style>